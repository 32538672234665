@import url(//fonts.googleapis.com/css?family=Open+Sans);
@font-face {
  font-family: "ProximaNova-Bold";
  src: local("ProximaNova-Bold"), url("./fonts/ProximaNova_Bold.otf");
  font-weight: 500;
}
@font-face {
  font-family: "ProximaNova-Medium";
  src: local("ProximaNova-Medium"), url("./fonts/ProximaNova-Medium.otf");
}
@font-face {
  font-family: "ProximaNova-SemiBold";
  src: local("ProximaNova-SemiBold"), url("./fonts/ProximaNova_SemiBold.otf");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: local("ProximaNova-Regular"), url("./fonts/ProximaNova_Regular.ttf");
}
@font-face {
  font-family: "ProximaNova-Light";
  src: local("ProximaNova-Light"), url("./fonts/ProximaNova_Light.otf");
}
@font-face {
  font-family: "ProximaNova-ExtraBold";
  src: local("ProximaNova-ExtraBold"), url("./fonts/ProximaNova_ExtraBold.otf");
}

body {
  margin: 0;
  font-family: "Proxima Nova", Fallback, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
